<template>
  <rect
    x="1"
    y="0.5"
    width="18"
    height="17"
    rx="8"
    fill="white"
    data-cy="FailIcon"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM9.59878 4.98814C9.87214 4.71477 10.3154 4.71477 10.5887 4.98814L11.0696 5.46897C11.3429 5.74234 11.3429 6.18555 11.0696 6.45892L9.47184 8.05664L11.0702 9.65496C11.3435 9.92833 11.3435 10.3715 11.0702 10.6449L10.5893 11.1257C10.316 11.3991 9.87275 11.3991 9.59938 11.1257L8.00106 9.52742L6.40265 11.1258C6.12929 11.3992 5.68607 11.3992 5.4127 11.1258L4.93187 10.645C4.6585 10.3716 4.6585 9.92841 4.93187 9.65504L6.53027 8.05664L4.93247 6.45884C4.65911 6.18547 4.65911 5.74226 4.93247 5.46889L5.41331 4.98806C5.68667 4.71469 6.12989 4.71469 6.40326 4.98806L8.00106 6.58586L9.59878 4.98814Z"
    fill="#FF595E"
  />
</template>
